import React, { useEffect, useState } from "react"
import { Button, TextField, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles"
import * as env from './env'
import Receipts from "./Receipts";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { isRTL } from "./common"

const styles = () => ({
    label: {
        alignSelf: 'center'
    },
    button: {
        backgroundColor: "#9B28C4",
        color: "white",
    },
    buttonContainer: {
        marginLeft: '8px',
    },
    menu: {
        width: 100,
    },
    textField: {
        width: '100%',
    },
    selectFocus: {
        "& .MuiSelect-select:focus": {
            backgroundColor: 'white',
        },
    },
    fontFamily: {
        fontFamily: env.getReceiptUIType() === "brightline" ? "Futura PT Bold" : 'Duplet'
    },
    countryValue: {
        "& .MuiSelect-outlined": {
            fontFamily: env.getReceiptUIType() === "brightline" ? "Futura PT Bold" : 'Duplet'
        }
    }
})

const Country = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { classes } = props
    const { t, i18n } = useTranslation()
    const [showReceipt, setShowReceipt] = useState(false)
    const [country, setCountry] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const [countries, setCountries] = useState([]);
    const currentLocale = i18n.language;

    useEffect(async () => {
        try {
            const apiEndPoint = `${env.getTenantBackendBaseUrl()}/stores/countries`
            console.log("fetch countries api endpoint: " + apiEndPoint)
            const response = await fetch(`${apiEndPoint}`, {
                method: "GET",
            })
            let json = await response.json()
            if (Array.isArray(json.countries) && json.countries.length > 0) {
                setCountries(json.countries)
            }
        } catch(error) {
            console.error("Fetch countries", error)
        }
    }, [])

    useEffect(() => {
        setCountry("")
    }, [!showReceipt]);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: 'black', // Change the color of option text when selected
            backgroundColor: state.isSelected ? '#D3D3D3' : 'white', // Change the background color of option when selected
            '&:hover': {
                backgroundColor: '#ECECEC', // Change the background color on hover
            },
        }),
    };

    const handleChange = (selectedCountry) => {
        const country = countries.filter(item => item.id === selectedCountry.id);
        setCountry(country);
    };

    const navigateToReceipts = () => {
        setShowReceipt(true)
        setSearchParams({["hl"]: currentLocale, ["country_id"]: country[0].id, ["country_name"]: country[0].name})
    }
    return (
        <div style={{ minHeight: 'calc(100vh - 180px)' }}>
            {(!showReceipt && !searchParams.get('country_name') && !searchParams.get('id') && !env.getReceiptUICountry()) ?
                <>
                    <Grid container style={{ paddingRight: isMobile ? '0px' : '8%', paddingLeft: isMobile ? '0px' : '8%', justifyContent: 'center', marginTop: 5 }} direction="row">
                        <Grid container style={{ alignSelf: 'center', justifyContent: isMobile ? (isRTL(currentLocale) ? 'right' : 'left') : 'flex-end', paddingBottom: isMobile ? '0px' : '30px' }} xs={8} md={2} sm={5} lg={2}>
                            <Grid item style={{ whiteSpace: 'nowrap', marginLeft: isRTL(currentLocale) ? 0 : '5px', marginBottom: 10 }}>{t('storeLocation')}
                            </Grid>
                        </Grid>
                        <Grid className={classes.buttonContainer} container xs={8} md={2} sm={5} lg={2} direction="row" style={{ marginLeft: 10, marginRight: 10 }}>
                            <Grid container direction="row" style={{ marginTop: isMobile ? 14 : 5, marginBottom: 10 }}>
                                <div style={{ width: "100%", overflow: "hidden" }}>
                                    <Select
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        isSearchable={false}
                                        placeholder=""
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                                text: 'orangered',
                                                primary25: '#FAFAFA',
                                                primary: '#D3D3D3',
                                            },
                                        })}
                                        styles={customStyles}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        menuPosition="fixed"
                                        onChange={handleChange}
                                        options={countries}
                                    />
                                </div>
                            </Grid>
                            <Grid md={12} lg={12} sm={12} xs={12} item style={{ textAlign: isRTL(currentLocale) ? 'left' : 'right' }}><Button
                                disabled={country ? false : true}
                                onClick={navigateToReceipts}
                                variant="contained"
                                style={{ marginTop: isMobile ? '10px' : '0px' }}
                                className={[classes.button, classes.fontFamily]}
                            >
                                {t('next')}
                            </Button></Grid>
                        </Grid>
                    </Grid>
                </>
                :
                <Receipts country={country} setShowReceipt={setShowReceipt} searchParams={searchParams}
                    setSearchParams={setSearchParams} countries={countries} />}
        </div >
    )
}

export default withStyles(styles)(Country)
