import React, { useEffect, useState } from "react"
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles"
import * as env from './env'
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSearchParams } from "react-router-dom";
import * as api from './api';
import englishTranslationText from './languages/english.json'
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const styles = () => ({
  label: {
    alignSelf: 'center'
  },
  button: {
    backgroundColor: "#9B28C4",
    color: "white",
  },
  buttonContainer: {
    marginLeft: '8px',
  },
  menu: {
    width: 100,
  },
  textField: {
    width: '100%',
  },
  selectFocus: {
    "& .MuiSelect-select:focus": {
      backgroundColor: '#fff',
    },
  },
  fontFamily: {
    fontFamily: env.getReceiptUIType() === "brightline" ? "Futura PT Bold" : 'Duplet'
  },
  countryValue: {
    "& .MuiSelect-outlined": {
      fontFamily: env.getReceiptUIType() === "brightline" ? "Futura PT Bold" : 'Duplet'
    }
  }
})

const Translation = (props) => {
  const { classes } = props
  const { i18n } = useTranslation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const allLanguages = localStorage.getItem("allLanguages") ? JSON.parse(localStorage.getItem("allLanguages")) : [{ "id": 7, "code": "en-US", "name": "English" }]
  let currentLocale = localStorage.getItem("currentLocale") ? JSON.parse(localStorage.getItem("currentLocale")) : "en-US"
  const [searchParams, setSearchParams] = useSearchParams({ ["hl"]: currentLocale });
  const translationText = JSON.parse(localStorage.getItem("translationText"))
  let currentLanguage = Array.isArray(allLanguages) && allLanguages.length > 0 ? allLanguages.find(lang => {
    return lang.code === currentLocale
  }) : null
  if (!currentLanguage) {
    currentLanguage = allLanguages.find(l => l.code === currentLocale)
  }
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage)

  useEffect(() => {
    (async function mount() {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const languageCode = queryParams.get('hl');
        let allLanguagesresponse = await api.getAllLanguages()
        if (!allLanguagesresponse) {
          allLanguagesresponse = allLanguages
        }
        if (currentLocale !== languageCode) {
          const isPresent = allLanguagesresponse.some(lang =>
            Object.values(lang).some(code =>
              typeof code === 'string' && code === languageCode
            )
          );
          if (isPresent) {
            currentLocale = languageCode
            localStorage.setItem("currentLocale", JSON.stringify(currentLocale))
          }
        }
        let translationResponse = await api.getTranslations(currentLocale)
        if (!translationResponse || translationResponse.error === true) {
          translationResponse = translationText ? translationText : JSON.stringify(englishTranslationText)
        }
        i18n.addResourceBundle(currentLocale, 'translation', JSON.parse(translationResponse), true, true);
        i18n.changeLanguage(currentLocale)
        localStorage.setItem("allLanguages", JSON.stringify(allLanguagesresponse))
        localStorage.setItem("translationText", JSON.stringify(translationResponse))
        searchParams.set('hl', currentLocale);

        // Construct the new URL with the updated search params
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        // Update the URL without reloading the page
        window.history.pushState({}, '', newUrl);

        let selectedLanguage = allLanguagesresponse.find(l => l.code === currentLocale)
        setSelectedLanguage(selectedLanguage)
      } catch (error) {
        console.error("getAllLanguages", error)
      }
    })();
  }, []);

  // useEffect(() => {
  //   document.body.dir = i18n.dir();
  // }, [i18n, i18n.language]);

  const changeAppLanguage = async (lang) => {
    try {
      const lng = allLanguages.find(item => item.id === lang.id).code
      if (lng !== currentLocale) {
        currentLocale = lng
        let translationResponse = await api.getTranslations(lng)
        if (!translationResponse || translationResponse.error === true) {
          translationResponse = translationText ? translationText : JSON.stringify(englishTranslationText)
          if (!currentLocale) {
            currentLocale = "en-US"
          }
        }

        searchParams.set('hl', currentLocale);
        // Construct the new URL with the updated search params
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        // Update the URL without reloading the page
        window.history.pushState({}, '', newUrl);

        i18n.addResourceBundle(currentLocale, 'translation', JSON.parse(translationResponse), true, true);
        i18n.changeLanguage(currentLocale)
        localStorage.setItem("allLanguages", JSON.stringify(allLanguages))
        localStorage.setItem("currentLocale", JSON.stringify(currentLocale))
        localStorage.setItem("translationText", JSON.stringify(translationResponse))
        let selectedLanguage = allLanguages.find(l => l.code === currentLocale)
        setSelectedLanguage(selectedLanguage)
        window.location.reload()
      }
    } catch (error) {
      console.error("changeAppLanguage", error)
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black', // Change the color of option text when selected
      backgroundColor: state.isSelected ? '#D3D3D3' : 'white', // Change the background color of option when selected
      '&:hover': {
        backgroundColor: '#ECECEC', // Change the background color on hover
      },
    }),
  };

  return (
    <>
        <Grid className={classes.buttonContainer} container direction="row" style={{paddingRight: 14, marginTop: isMobile ? 14 : 5}}>
        <div style={{width: isMobile ? 150 : 200, overflow: "hidden" }}>
        <Select
        components={{
          IndicatorSeparator: () => null
        }}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
          ...theme.colors,
            text: 'orangered',
            primary25: '#FAFAFA',
            primary: '#D3D3D3',
          },
        })}
        styles={customStyles}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="fixed"
        value={selectedLanguage ? selectedLanguage : ""}
        onChange={changeAppLanguage}
        options={allLanguages}
      />
      </div>
      </Grid>
    </>
  )
}

export default withStyles(styles)(Translation)